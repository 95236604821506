/* Tab.css */
.custom-tab {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .custom-link {
    text-decoration: none;
    color: #333; /* Customize the text color */
    padding: 10px 20px;
    border: 2px solid #007bff; /* Add a border */
    border-radius: 5px;
    background-color: #f8f8f8; /* Customize the background color */
    margin: 5px;
    display: inline-block;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .custom-link:hover {
    background-color: #007bff; /* Change background color on hover */
    color: #fff; /* Change text color on hover */
  }
  