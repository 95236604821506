:root{
    --bs-danger:#f1416c
};
.is-invalid{
    color: var(--bs-danger);
    font-size: 1rem;
    font-weight: 400;
}
.is-invalid,.fv-plugins-message-container .fv-help-block{
    
    color: var(--bs-danger);
    font-size: 1rem;
    font-weight: 400;

}
.fv-plugins-message-container .fv-help-block{
    font-size: small;
    text-align: start;
}